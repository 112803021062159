import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { invalidateUser } from "../redux/auth";

import arrowdown from "../assets/images/arrowdown.svg";

const Navbar = ({ user }) => {
  const [navOpen, setNavOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  function logout() {
    localStorage.removeItem("authToken");
    dispatch(invalidateUser());
    history.push("/");
  }

  return (
    <div className="flex items-center justify-between w-full text-black mb-4 md:mb-8">
      <div>
        <Link
          to="/dashboard"
          className="text-xl md:text-3xl font-bold text-black py-2"
        >
          Budgetta
        </Link>
      </div>
      <div className="">
        <div
          className="flex cursor-pointer items-center md:w-100 py-3 px-6 bg-pink-400 rounded-full hover:bg-pink-500 text-white text-sm md:text-lg"
          onClick={() => setNavOpen(!navOpen)}
        >
          {user ? user.email : ""}{" "}
          <img src={arrowdown} className="w-4 ml-2" alt="arrow" />
        </div>
        <ul
          className={
            "w-60 absolute list-none bg-white rounded-xl shadow-md text-center " +
            (navOpen === false ? "hidden" : "")
          }
        >
          <li className="">
            <Link to="/settings" className="block py-3">
              Settings
            </Link>
          </li>
          <li className="">
            <Link to="/bill-payments" className="block py-3">
              Pay Bills
            </Link>
          </li>
          <li className="cursor-pointer py-3" onClick={logout}>
            Logout
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
