import {useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import { Link } from 'react-router-dom';
import config from '../config';

import { useState } from 'react';
import { useDispatch} from 'react-redux';
import { setAuthenticated } from '../redux/auth';
import arrow1 from '../assets/images/arrow1.svg'
import loading from '../assets/images/loading.svg'
import Main from './Main';


export default function Login() {
    const history = useHistory();
    const dispatch = useDispatch();
 //   const user = useSelector(state => state.auth.user); 
    const [formError, setFormError] = useState(null);

    async function login(values, { setSubmitting }) {
        //authenticate details with remote server
        const response = await fetch(config.api_endpoint + 'login', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: values.email,
                password: values.password,
            })
        });
        
        if (response.status === 401) {
            setFormError("There was an error submitting your form. Please try again later");
            return;
        }

        setFormError(null);

        let authToken = response.headers.get('Authorization');
        localStorage.setItem("authToken", authToken);

        dispatch(setAuthenticated(true));

        //if successful, redirect to app
        history.push('/dashboard');
    }

    return (
        <div>
            <Main>
             <Formik initialValues={
                    {email: '', password: ''}
                 } onSubmit={login}>
                {
                    ({handleSubmit, values, handleChange, handleBlur, isSubmitting}) => (
                    
                        <div className="bg-gray-800 min-h-screen pt-5 ">
                            <div className="justify-self-center mx-auto w-4/5 md:w-1/3">
                               
                                <form onSubmit={handleSubmit} className="">
                                    <h2 className="text-white text-lg text-center md:text-3xl normal-case font-sans font-bold mb-5 md:mb-10">Login to your account</h2>
                                                                                            
                                    {formError &&
                                        (<div className="p-3 border-2 border-red-400 rounded-xl mb-5  text-gray-300 text-xs md:text-base ">
                                            {formError}
                                        </div>)}
                                    <input type="email" name="email" placeholder="Email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email} className="bg-darker border-0 w-full h-8 md:h-12 text-left pl-6 text-gray-100 mb-3 text-sm md:text-lg rounded-lg" /> <br/>
                                    <span><i></i></span>
                                    <input type="password" name="password" placeholder="Password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password} className="bg-darker border-0 w-full h-8 md:h-12 text-left pl-6 text-gray-100 mb-4 text-sm md:text-lg rounded-lg"/> <br/>
                        
                                        {!isSubmitting && <button type="submit" className="flex justify-between items-center bg-red-400 border-0 w-full h-8 md:h-12 p-2 text-gray-100  mb-10 rounded-lg">
                                            <p className="font-semibold text-xs md:text-lg pl-4">Login to your account</p>
                                            <img src={arrow1} alt="Login" className= "p-1 md:p-2 pr-2 md:pr-4 w-6 md:w-12" />
                                        </button>}
                        
                                    {isSubmitting && <button type="submit" className="flex justify-between items-center bg-red-300 border-0 w-full h-8 md:h-12 p-2 text-gray-100 mb-10 rounded-lg">
                                        <p className="font-semibold text-xs md:text-lg pl-4">Loading</p>
                                        <img src={loading} alt="Login" className="p-1 md:p-2 pr-2 md:pr-4 w-6 md:w-12" />
                                    </button>}
                                    
                                    <div className="flex flex-col">
                                        <Link to="forgot-password" className="text-red-500 underline mb-2 text-xs md:text-base md:font-semibold"> Forgot Password? </Link> 
                                        <p className="text-xs md:text-base md:font-semibold text-white">Don't have an account? <Link to="/signup" className="text-red-500 underline">Sign up</Link></p>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                      
                    )
                }
            </Formik>
            </Main>
        </div>
    )
}