import React, { useCallback, useEffect, useState } from 'react'
import Navbar from './navbar'
import Authenticated from './Authenticated';

import { useDispatch, useSelector } from 'react-redux';
import {formatDate, formatNaira} from '../services/format'
import { fetchAccounts } from '../redux/account';
import config from '../config';

import caution from "../assets/images/caution.svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const Transactions = () => {
    const user = useSelector(state=> state.auth.user);
    const [transactions, setTransactions] = useState([]);
    const accounts = useSelector(state=>state.account.all);
    const [selectedAccount, setSelectedAccount] = useState("");

    const dispatch = useDispatch();

    

    const fetchTransactions = useCallback(async () => {
        const authToken = localStorage.getItem("authToken");
        let url = config.api_endpoint + 'transactions';

        if (selectedAccount){
            url += '?account=' + selectedAccount;
        }

        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': "application/json",
                'Authorization': authToken,
            }
        });
    
        setTransactions(await resp.json());
    }, [selectedAccount]);

    useEffect(() => {
        if(user){
            dispatch(fetchAccounts());
            fetchTransactions(); 
        }        
    }, [user, dispatch, fetchTransactions]);

    useEffect(() => {
        if(user){
            fetchTransactions();
        }
    }, [user, dispatch, selectedAccount, fetchTransactions]);

    const changeAccount = (e) => {
       setSelectedAccount(e.target.value);
    }

    return (
        <Authenticated>
            <Navbar user={user} />
         
            <div className=" flex mb-4"> 
                <p className="border-b-4 border-blue-200 font-bold text-xl w-32 pb-2">Transactions</p>
                <div className="border-b-2 border-gray-200 w-full"></div>
            </div>
            
            {transactions.length > 0 ? 
            <div>
            <div className=''>
                {/* <p className="text-black font-bold">No bank selected</p>
                <img src={arrow1} className="w-4 ml-2" alt="arrow" />
                cursor-pointer w-48 p-2 bg-gray-100 rounded-lg text-white text-sm md:text-sm mb-4 */}

                 <select value={selectedAccount} onChange={changeAccount} as="select" className="bg-gray-200 w- w-1/4 h-10 rounded-xl text-lg p-2 mb-4">
                                <option value="">No account selected </option>
                                    {accounts.map(account=> (
                                <option key={account.id} value={account.id}>{account.name}</option>
                                    ))}
                 </select>
            </div>
            <div>
                <table className="border-0 border-collapse table-fixed w-full p-2">
                    <thead>
                        <tr className="text-left border">
                            <th className="border-0 border-gray-200 p-2">Date</th>
                            <th className="border-0 border-gray-200 p-2">Amount</th>
                            <th className="border-0 border-gray-200 p-2">Description</th>
                            <th className="border-0 border-gray-200 p-2">Budget</th>
                            <th className="border-0 border-gray-200 p-2">Account</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {transactions.map((transaction) =>
                            (<tr className="border-b border-gray-200" key={transaction.id}>
                            <td className="p-2 ">{formatDate(transaction.created_at)}</td>
                            <td className="p-2 ">
                                {transaction.tx_type === 1 ? "":"-"}
                                {formatNaira(transaction.amount)}
                            </td>
                            <td className="p-2">{transaction.description}</td>
                            <td className="p-2 ">{transaction.budget.name}</td>
                            <td className="p-2 ">{transaction.account ? transaction.account.name : '-'}</td> 
                        </tr>
                        ))}

                    </tbody>
                </table>
             </div>
            </div>
            :  
            <div className=" md:w-full border-2 border-gray-200 rounded-lg py-10 md:py-20 mt-5">
                <div className="flex justify-center gap-4 p-5">
                    <img src={caution} className="" alt="caution"/>
                    <div className=""> 
                        <p className="text-sm md:text-xl font-semibold">You currently have no transactions.   
                        <Link className="text-blue-400" to="/add-expense"> Log an expense</Link> or 
    <Link className="text-blue-400" to="/record-income"> add to your income</Link> </p>
                    </div>
                </div>                
            </div>
             }
        </Authenticated>
    ) 

}

export default Transactions