import {Formik} from 'formik';
import { Link } from 'react-router-dom';
import config from '../config';
import arrow1 from '../assets/images/arrow1.svg'
import loading from '../assets/images/loading.svg'
import Main from './Main';

import { useState } from 'react';


export default function ForgotPassword() {
    const [formMessage, setFormMessage] = useState(null);

    async function login(values, { setSubmitting }) {
        let formData = new FormData();
        formData.append("email", values.email);

        const response = await fetch(config.api_endpoint + 'forgot-password', {
            method: 'POST',
            body: formData,
        });
        
        if (response.status !== 200) {
            setFormMessage("Failed to generate a password reset link. Please try again later.");
            return;
        } setFormMessage(null);
        
        setFormMessage("A reset password link has been sent to your email address.");
    }

    return (
        <div>
            <Main>
            <Formik
                initialValues={
                    {email: '', password: ''}
                }
                onSubmit={login}
            >
                {
                    ({handleSubmit, values, handleChange, handleBlur, isSubmitting}) => (
                        <div className="bg-gray-800 min-h-screen pt-5">
                            <div className="justify-self-center mx-auto w-4/5 md:w-1/3">
                                <form onSubmit={handleSubmit} className="">
                                    <h2 className="text-white text-lg md:text-3xl normal-case font-sans font-bold mb-5 md:mb-10 text-center">Forgot Password</h2>
                                    
                                    {formMessage &&
                                        (<div className="p-3 border-2 border-red-400 rounded-xl w-full0 text-gray-300 text-xs md:text-base mb-5">
                                            {formMessage}
                                        </div>)}
                                    <input type="email" name="email" placeholder="Email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        className="bg-darker border-0 w-full h-8 md:h-12 text-left pl-6 text-gray-100 mb-3 text-sm md:text-lg rounded-lg" /> <br/>
                        
                                        {!isSubmitting && <button type="submit" className="flex justify-between bg-red-400 border-0 w-full h-8 md:h-12 p-2 text-gray-100  mb-5 rounded-lg">
                                            <p className="font-semibold text-xs md:text-lg pl-4">Reset Password
                                            </p>
                                            <img src={arrow1} alt="Login" className= "p-1 md:p-2 pr-2 md:pr-4 w-6 md:w-12" />
                                        </button>}

                                        {isSubmitting && <button type="submit" className="flex justify-between items-center bg-red-300 border-0 w-full h-8 md:h-12 p-2 text-gray-100 mb-5 rounded-lg">
                                        <p className="font-semibold text-xs md:text-lg pl-4">Loading</p>
                                        <img src={loading} alt="Login" className="p-1 md:p-2 pr-2 md:pr-4 w-6 md:w-12" />
                                    </button>}
                                                                       
                                    <Link to="/login" className="text-xs md:text-base text-red-400 underline md:text-semibold"> Back to Login</Link>
                                    
                                </form>
                            </div>
                        </div>
                      
                    )
                }
            </Formik>
            </Main>
        </div>
    )
}