import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import logo from '../assets/images/logo.svg';

const Main = ({children}) => {
    const user = useSelector(state=> state.auth.user)


return (
  <div className="">
    <div className="flex bg-gray-800 items-center justify-between p-5 md:p-10 pb-5 md:pb-10 ">
        <Link to="/homepage" class="  w-20 md:w-2/12 lg:w-200"><img src={logo} alt="logo" /></Link>
        {(user === null) ? <div class="flex">
            <Link to="/login" class="bg-gray-800 text-white text-xs md:text-sm py-2 px-2 md:py-2 md:px-6 border-2 border-white rounded-full mx-2 hover:bg-gray-500">Login</Link>
            <Link to="signup" class="bg-green-400 inline text-white text-xs md:text-sm py-2 px-6 border-2 border-white hover:bg-green-200 rounded-full">Create an account</Link>   
        </div>
            : <Link to="/dashboard" class="inline bg-green-400 hover:bg-green-200 text-white text-sm py-2 md:py-3 px-6 border-2 border-white rounded-full">Go to Dashboard</Link>
          }
    </div>
        {children}  

    <div className="bg-gray-800 p-5 md:p-10">
        <div className="text-white text-xs">
          <p>Copyright 2021. All rights reserved. </p>
        </div>
    </div>
  </div>
)

        }

export default Main