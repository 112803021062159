import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "./navbar";
import { fetchBudgets } from "../redux/budget";
import Authenticated from "./Authenticated";
import { fetchTransactions } from "../redux/transaction";
import { formatDate, formatNaira } from "../services/format";

import add from "../assets/images/add.svg";
import debitIcon from "../assets/images/debitIcon.svg";
import creditIcon from "../assets/images/creditIcon.svg";
import accountsarrow from "../assets/images/accountsarrow.svg";
import { fetchAccounts } from "../redux/account";
import caution from "../assets/images/caution.svg";

const Dashboard = () => {
  const user = useSelector((state) => state.auth.user);
  const budgets = useSelector((state) => state.budget.all);
  const transactions = useSelector((state) => state.transaction.all);
  const accounts = useSelector((state) => state.account.all);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(fetchBudgets());
      dispatch(fetchTransactions());
      dispatch(fetchAccounts());
    }
  }, [user, dispatch]);

  function budgetLeft() {
    return budgets.reduce((sum, n) => parseInt(n.left) + sum, 0);
  }

  function expenses() {
    return transactions.reduce((sum, n) => {
      if (n.tx_type === 2) {
        return sum + n.amount;
      }
      return sum;
    }, 0);
  }

  return (
    <Authenticated>
      <Navbar user={user} />

      {/* DASHBOARD SECTION */}

      <div className="flex flex-col md:flex-row justify-between mb-4 gap-4">
        <div className="flex bg-green-300 p-5 rounded-3xl justify-between w-full md:w-1/2 mb-2 md:mb-0">
          <div className=" ">
            <p className="text-sm md:text-xl font-bold">Available in Budget</p>
            <p className="text-lg md:text-xl font-extrabold">
              {formatNaira(budgetLeft())}
            </p>
          </div>
          <Link to="/record-income" className="">
            <img src={add} className="w-8 md:w-10 m-3" alt="Add Button" />
          </Link>
        </div>
        <div className="flex bg-yellow-200 p-5 justify-between rounded-3xl w-full md:w-1/2">
          <div className="">
            <p className="text-sm md:text-xl font-bold">Your Expenses</p>
            <p className="text-xl font-extrabold">{formatNaira(expenses())}</p>
          </div>
          <Link to="/add-expense" className="">
            <img src={add} className="w-8 md:w-10 m-3" alt="Add Button" />
          </Link>
        </div>
      </div>

      {/* BUDGET & TRANSACTION SECTION */}

      <div className="flex justify-between flex-col md:flex-row mb-8">
        {/* BUDGET SECTION */}

        <div className="mb-4 w-full md:w-1/2 mr-8">
          <div className="flex justify-between">
            <div>
              <p className="text-2xl font-bold text-left mb-4">Budget</p>
            </div>
            <Link to="/add-Budget" className="">
              <img src={add} className="w-8 md:w-10 m-3" alt="Add Button" />
            </Link>
          </div>
          {budgets.length > 0 ?

           <div> 
            {budgets.map((budget) => (
            <div key={budget.id}>
              <div className=" flex justify-between mb-2 ">
                <div className="">
                  <p className="mb-2 text-xl">{budget.name} </p>
                  <div className="bg-yellow-200 p-1 rounded-3xl w-3/4"></div>
                </div>
                <div className="text-right">
                  <p className="text-xl font-bold">
                    {formatNaira(budget.balance)}
                  </p>
                  <p className="text-gray-500">{formatNaira(budget.left)}</p>
                </div>
              </div>
            </div>
             ))} 
             </div>
            :
            <div className=" md:w-full border-2 border-gray-200 rounded-3xl py-5 md:py-10">
                <div className="flex justify-center gap-4">
                    <img src={caution} className="" alt="caution"/>
                    <div className=""> 
                        <p className="text-sm md:text-xl font-semibold">You currently have no budgets. </p>  
                        <Link className="text-blue-400 text-sm md:text-xl font-semibold" to="/add-budget"> Create a budget</Link>
                        
                    </div>
                </div>                
            </div>
            }
        </div>

        {/* TRANSACTIONS SECTION */}
        <div className="w-full md:w-1/2">
          <div className="">
            <p className="text-2xl font-bold pr-96 text-left mb-4">
              Transactions
            </p>
          </div>

          {transactions.length>0 ?
          <div className="mb-4">
            
            <div>
              {transactions.map((transaction) => (
                <div key={transaction.id} className="flex justify-between mb-3">
                  <div className="flex">
                    <div to="/add-budget" className="">
                      {transaction.tx_type === 1 ? (
                        <img
                          src={debitIcon}
                          className="w-8 md:w-7 mr-2"
                          alt="debit"
                        />
                      ) : (
                        <img
                          src={creditIcon}
                          className="w-8 md:w-7 mr-2"
                          alt="credit"
                        />
                      )}
                    </div>
                    <div>
                      <p className="text-xl">{transaction.description}</p>
                      <p className="text-gray-500">{transaction.budget.name}</p>
                    </div>
                  </div>

                  <div className="text-right">
                    <p className="text-xl font-bold">
                      {transaction.tx_type === 1 ? "" : "-"}
                      {formatNaira(transaction.amount)}
                    </p>
                    <p className="text-gray-500">
                      {formatDate(transaction.created_at)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-blue underline">
              <Link to="/transactions">See more transactions...</Link>
            </div>
          </div>
          :
          <div className=" md:w-full border-2 border-gray-200 rounded-3xl py-5 md:py-10">
                <div className="flex justify-center gap-4">
                    <img src={caution} className="" alt="caution"/>
                    <div className=""> 
                        <p className="text-sm md:text-xl font-semibold">You currently have no transactions. </p>
                        <Link className="text-blue-400 text-sm md:text-xl font-semibold" to="/add-expense"> Add a transaction</Link>
                    </div>
                </div>                
            </div>
          }
          
        </div>
      </div>

      {/* ACCOUNTS SECTION */}

      <div className="w-full md:w-1/2">
        <div className="flex justify-between w-full mb-5 ">
          <p className="text-lg font-bold md:text-2xl">Your Accounts</p>
          <p>
            <Link to="/accounts" className="text-blue underline m-2">
              Create an account
            </Link>
          </p>
        </div>
        
        {accounts.length>0 ?
          <div>
          {accounts.map((account) => (
          <div className="flex justify-between border-2 rounded-xl p-2 mb-2 items-center"
            key={account.id}
          >
            <div className="px-4 font-bold">{account.name}</div>
            <div className="flex">
              <div className="bg-blue-300 p-2 rounded-xl ">
                <p className="text-white">{formatNaira(account.balance)}</p>
              </div>
              <div>
                <img src={accountsarrow} alt="expand" className="w-3 mt-4 mr-2 ml-2 mb-2"/>
              </div>
            </div>
          </div>
        ))}
        </div>
        :
        <div className=" md:w-full border-2 border-gray-200 rounded-3xl py-5 md:py-10">
          <div className="flex justify-center gap-4 ">
              <img src={caution} className="" alt="caution"/>
              <div className=""> 
                  <p className="text-sm md:text-xl font-semibold">You currently have no accounts.</p>  
                  <Link className="text-blue-400 text-sm md:text-xl font-semibold" to="/accounts"> Create account</Link>
              </div>
          </div>                
        </div>
        }
      </div>
    </Authenticated>
  );
};

export default Dashboard;
