import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from "../config";

export const fetchBudgets = createAsyncThunk('budget/fetchBudgets', async () => {
    const authToken = localStorage.getItem("authToken");
    const resp = await fetch(config.api_endpoint + 'budgets', {
        method: 'GET',
        headers: {
            'Content-Type': "application/json",
            'Authorization': authToken,
        }
    });

    return await resp.json();
});

export const addBudget = createAsyncThunk('budget/addBudget', async (data) => {
    const authToken = localStorage.getItem("authToken");
    const formData = new FormData();
    formData.append('name', data.name);
    const resp = await fetch(config.api_endpoint + 'budgets/add', {
        method: 'POST',
        headers: {
            'Authorization': authToken,
        },
        body: formData,
    });

    return await resp.json();
});

export const slice = createSlice({
    name: 'budget',
    initialState: {
        all: []
    },
    extraReducers: {
        [fetchBudgets.fulfilled]: (state, action) => {
            state.all = action.payload;
        },
        [addBudget.fulfilled]: (state, action) => {
            state.all.push(action.payload);
        }
    }
});

export default slice.reducer;