import React, { useEffect } from 'react';
import Navbar from './navbar'
import { Link, useHistory } from 'react-router-dom'
import Authenticated from './Authenticated';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBudgets } from '../redux/budget';
import { Field, FormikProvider, useFormik } from 'formik';
import { addTransaction } from '../redux/transaction';
import { fetchAccounts } from '../redux/account.js';

const AddExpense = () =>{
    const history = useHistory();
    const budgets = useSelector(state => state.budget.all);
    const accounts = useSelector(state=> state.account.all)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchBudgets());
        dispatch(fetchAccounts())
    }, [dispatch]);

    const formik = useFormik({
        initialValues: {
            amount: '',
            description: '',
            budget: '',
            account:'',
        },
        onSubmit: values => {
            if (!values.budget) {
                values.budget = budgets[0].id;
            }
            
            dispatch(addTransaction({
                amount: values.amount,
                description: values.description,
                budget: values.budget,
                type: 2,
                account: values.account,
            }));

            history.push('/dashboard');
        }
    });

    const user = useSelector(state => state.auth.user);

    return (
        <Authenticated>
            <div className="">
                <Navbar user={user} />
                <div className="justify-center w-full md:w-80 mx-auto">
                    <h1 className="text-xl md:text-3xl font-bold color-black mb-6"> Record Expense</h1>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                                <Field className="bg-gray-200 rounded-xl w-80 md:w-80 h-10 mb-4 text-left text-sm md:text-lg" placeholder="Amount" name="amount" value={formik.values.amount} />
                                <Field className="bg-gray-200 w-full h-10 rounded-xl p-2 mb-4 text-left text-sm md:text-lg" placeholder="Description" name="description" value={formik.values.description} />
                                <Field  placeholder="Budget" className="bg-gray-200 w-full h-10 rounded-xl p-2 mb-4 text-left text-sm md:text-lg" name="budget" value={formik.values.budget} as="select" >
                                    <option className="text-gray-300">Select Budget</option>                                   
                                    {budgets.map(budget => (
                                        <option key={budget.id} value={budget.id}>{ budget.name }</option>
                                    ))}
                                </Field>
                                <Field placeholder="Account" name="account" 
                                value={formik.values.account} as="select" className="bg-gray-200 w-80 h-10 rounded-xl p-2 mb-4">
                                    <option>Select Account</option>
                                    {accounts.map(account=> (
                                        <option key={account.id} value={account.id}>{account.name}</option>
                                    ))}
                                </Field>
                            </div>
                            <div>
                                <button type="submit" className="bg-pink-400 rounded-xl px-8 py-2 text-lg font-sans hover:bg-pink-500 text-white m-2"> Save </button>
                                <Link to="dashboard" className="bg-gray-200 rounded-xl px-8 py-2 text-lg font-sans hover:bg-gray-300 text-white"> Cancel </Link>
                            </div>
                        </form>
                    </FormikProvider>
                </div>
            </div>
        </Authenticated>
    )
}

export default AddExpense