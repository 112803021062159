import React from 'react';
import Navbar from './navbar'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Authenticated from './Authenticated';
import { addBudget } from '../redux/budget';

const AddBudget = () =>{
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    

    const formik = useFormik({
        initialValues: {
            title: '',
        },
        onSubmit: values => {
            dispatch(addBudget({
                name: values.title,
            }));
            
            history.push('/dashboard');
        }
    });

    return (
        <Authenticated>
            <div className="">
                <Navbar user={user} />
                <div className="justify-center w-80 mx-auto">
                    <form onSubmit={formik.handleSubmit}>
                        <h1 className="text-xl font-bold color-black mb-6"> Add budget category</h1>
                        <div className="">
                            <input className="bg-gray-200 w-80 h-10 rounded-xl p-4 mb-4" placeholder="Title" name="title" onChange={formik.handleChange} value={formik.values.title} />
                            
                        </div>
                        <div>
                            <button type="submit" className="bg-pink-400 rounded-xl px-8 py-2 text-lg font-sans hover:bg-pink-500 text-white m-2"> Save </button>
                            <Link to="dashboard" className="bg-gray-200 rounded-xl px-8 py-2 text-lg font-sans hover:bg-gray-300 text-white"> Cancel </Link>
                        </div>
                    </form>
                </div>
            </div>
        </Authenticated>
    )
}

export default AddBudget