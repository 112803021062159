import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from "../config";

export const fetchProfile = createAsyncThunk('auth/fetchProfile', async () => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
        const resp = await fetch(config.api_endpoint + 'me', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken,
            }
        })

        if (resp.status === 401) {
            return null;
        }
        
        const data = await resp.json();
        if (data.status === 401) {
            return null;
        }

        return data;
    }

    return null;
});

export const signupUser = (email, password) => {
    return async dispatch => {
        const response = await fetch(config.api_endpoint + 'signup', {
            method: 'POST',
            body: JSON.stringify({
                email, password
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        return {
            status: response.status,
            data,
        };
    }
}

export const handleUnauthorized = () => {
    return () => {
        window.location = 'login';
    }
}

export const slice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        authenticated: null,
    },
    reducers: {
        invalidateUser(state) {
            state.user = null;
            state.authenticated = null;
        },
        setAuthenticated(state, action) {
            state.authenticated = action.payload;
        },
    },
    extraReducers: {
        [fetchProfile.fulfilled]: (state, action) => {
            if (action.payload !== null) {
                state.user = action.payload;
                state.authenticated = true;
            } else {
                console.log(action.payload)
                state.authenticated = false;
            }
        }
    }
});

export const { invalidateUser, setAuthenticated } = slice.actions;

export default slice.reducer;