import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchProfile } from '../redux/auth';

const Authenticated = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const authenticated = useSelector(state => state.auth.authenticated);

    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    useEffect(() => {
        if (authenticated === false) {
            history.push('/');
        }
    }, [history, authenticated]);
    
    return <div className="px-4 py-2 md:px-8 md:py-4">
        {children}
    </div>
}

export default Authenticated;