import React from "react";
import { Switch, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import Login from "./components/Login";
import Signup from "./components/Signup";
import { Provider } from "react-redux";
import store from "./redux/store";
import Dashboard from "./components/Dashboard";
import addExpense from "./components/addExpense";
import addBudget from "./components/addBudget";
import ForgotPassword from "./components/ForgotPassword";
import RecordIncome from "./components/RecordIncome";
import Transactions from "./components/Transactions";
import Accounts from "./components/EditAccount";
import Settings from "./components/settings";
import BillPayments from "./components/BillPayments";

function App() {
  return (
    <Provider store={store}>
      <div className="App ">
        <Switch>
          <Route path="/add-expense" exact component={addExpense} />
          <Route path="/record-income" exact component={RecordIncome} />
          <Route path="/add-budget" exact component={addBudget} />
          <Route path="/forgot-password" exact component={ForgotPassword} />

          <Route path="/bill-payments">
            <BillPayments />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/accounts">
            <Accounts />
          </Route>
          <Route path="/transactions">
            <Transactions />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/">
            <Homepage />
          </Route>
        </Switch>
      </div>
    </Provider>
  );
}

export default App;
