import { configureStore } from "@reduxjs/toolkit";
import authReducer from '../redux/auth'
import accountReducer from "./account";
import budgetReducer from "./budget";
import transactionReducer from "./transaction";

export default configureStore({
    reducer: {
        auth: authReducer,
        budget: budgetReducer,
        transaction: transactionReducer,
        account: accountReducer,
    },
});