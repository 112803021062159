import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from "../config";

export const fetchAccounts = createAsyncThunk('account/fetchAccounts', async () => {
    const authToken = localStorage.getItem("authToken");
    const resp = await fetch(config.api_endpoint + 'accounts', {
        method: 'GET',
        headers: {
            'Content-Type': "application/json",
            'Authorization': authToken,
        }
    });

    return await resp.json();
});

export const addAccount = createAsyncThunk('account/addAccount', async(data) => {
    const authToken = localStorage.getItem("authToken");
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('balance', data.balance);
    const resp = await fetch(config.api_endpoint + 'accounts/add', {
        method: 'POST',
        headers: {
            'authorization': authToken,
        }, 
        body: formData,
    });
    return await resp.json();

})

export const slice = createSlice({
    name: 'account',
    initialState: {
        all: []
    },
    extraReducers: {
        [fetchAccounts.fulfilled]: (state, action) => {
            state.all = action.payload;
        },
        [addAccount.fulfilled]: (state, action) => {
            state.all.push(action.payload);
        } 
    }
});

export default slice.reducer;