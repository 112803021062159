import moment from 'moment';

export function formatNaira(n) {
    if (n === undefined) return '';
    return 'N' + n.toLocaleString();
}

export function formatDate(dt) {
    return dt ? moment(dt).format('MMMM D YY, h:mm a') : '';
}

