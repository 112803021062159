import React, { useState } from "react";
import Authenticated from "./Authenticated";
import Navbar from "./navbar";
import { useDispatch, useSelector } from "react-redux";
import { Field, FormikProvider, useFormik } from "formik";
import config from "../config";
import { useHistory } from "react-router";
import { invalidateUser } from "../redux/auth";

const Settings = () => {
  const user = useSelector((state) => state.auth.user);
  const [formSuccess, setFormSuccess] = useState();
  const [formError, setFormError] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  function logout() {
    localStorage.removeItem("authToken");
    dispatch(invalidateUser());
    history.push("/");
  }

  const emailFormik = useFormik({
    initialValues: {
      newEmail: "",
    },
    onSubmit: async (values) => {
      const authToken = localStorage.getItem("authToken");
      const formData = new FormData();
      formData.append("new_email", values.newEmail);
      const resp = await fetch(config.api_endpoint + "settings/change-email", {
        method: "POST",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      });

      const response = await resp.json();
      if (resp.status === 403) {
        setFormSuccess("");
        setFormError(response.message);
        return;
      }

      setFormError("");
      setFormSuccess(
        response.message + " Please log in again with your new email address."
      );
      setTimeout(() => {
        logout();
      }, 3000);
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    },
    onSubmit: async (values) => {
      const authToken = localStorage.getItem("authToken");
      const formData = new FormData();
      formData.append("old_password", values.oldPassword);
      formData.append("new_password", values.newPassword);
      const resp = await fetch(
        config.api_endpoint + "settings/change-password",
        {
          method: "POST",
          headers: {
            Authorization: authToken,
          },
          body: formData,
        }
      );

      const response = await resp.json();
      if (resp.status === 403) {
        setFormSuccess("");
        setFormError(response.message);
        return;
      }

      setFormError("");
      setFormSuccess(response.message);
    },
  });

  return (
    <Authenticated>
      <Navbar user={user} />
      <div className="flex mb-6 w:mb-8">
        <p className="border-b-4 border-blue-200 font-bold text-xl w-32 pb-2">
          Settings
        </p>
        <div className="border-b-2 border-gray-200 w-full"></div>
      </div>

      <div>
        {formSuccess && (
          <div className="text-xs md:text-base text-gray-600 rounded-xl p-2 bg-green-300 mb-3 md:mb-6">
            {formSuccess}
          </div>
        )}
        {formError && (
          <div className="text-xs md:text-base text-gray-600 rounded-xl p-2 bg-red-300 mb-3 md:mb-6">
            {formError}
          </div>
        )}
      </div>

      <div className="flex justify-between flex-col md:flex-row md:gap-10">
        <div className="w-full md:w-1/2 ">
          <h1 className="text-xl md:text-2xl font-medium color-black mb-3 md:mb-6">
            Update Email Address
          </h1>
          <FormikProvider value={emailFormik}>
            <form onSubmit={emailFormik.handleSubmit} className="mb-8">
              <div>
                <Field
                  className="bg-gray-200 rounded-xl w-3/4 p-2 h-10 text-left mb-3 md:mb-4 border-2 text-sm md:text-lg"
                  value={emailFormik.values.newEmail}
                  name="newEmail"
                  placeholder="New email address"
                />
              </div>
              <button
                type="submit"
                className="bg-pink-400 rounded-xl px-6 py-1 text-lg font-sans hover:bg-pink-500 text-white"
              >
                {" "}
                Save{" "}
              </button>
            </form>
          </FormikProvider>
        </div>

        <div className="w-full md:w-1/2">
          <h1 className="text-xl md:text-xl font-medium color-black mb-3 md:mb-6">
            Change your Password
          </h1>
          <FormikProvider value={passwordFormik}>
            <form onSubmit={passwordFormik.handleSubmit}>
              <div>
                <Field
                  className="bg-gray-200 rounded-xl w-full md:w-3/4 p-2 h-10 text-left mb-2 md:mb-4 text-sm md:text-lg"
                  value={passwordFormik.values.oldPassword}
                  name="oldPassword"
                  placeholder="Old password"
                  type="password"
                />
                <Field
                  className="bg-gray-200 rounded-xl w-full md:w-3/4 p-2 h-10 text-left mb-2 md:mb-4 text-sm md:text-lg"
                  value={passwordFormik.values.newPassword}
                  name="newPassword"
                  placeholder="New password"
                  type="password"
                />
                <Field
                  className="bg-gray-200 rounded-xl w-full md:w-3/4 p-2 h-10 text-left mb-3 md:mb-4 text-sm md:text-lg"
                  value={passwordFormik.values.repeatNewPassword}
                  name="repeatNewPassword"
                  placeholder="Repeat new password"
                  type="password"
                />
              </div>

              <button
                type="submit"
                className="bg-pink-400 rounded-xl px-6 py-1 text-lg font-sans hover:bg-pink-500 text-white"
              >
                {" "}
                Save{" "}
              </button>
            </form>
          </FormikProvider>
        </div>
      </div>
    </Authenticated>
  );
};

export default Settings;
