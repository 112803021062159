import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from "../config";

export const fetchTransactions = createAsyncThunk('budget/fetchTransactions', async () => {
    const authToken = localStorage.getItem("authToken");
    const resp = await fetch(config.api_endpoint + 'transactions', {
        method: 'GET',
        headers: {
            'Content-Type': "application/json",
            'Authorization': authToken,
        }
    });

    return await resp.json();
});

export const addTransaction = createAsyncThunk('transaction/add', async (data) => {
    const authToken = localStorage.getItem("authToken");
    const formData = new FormData();
    formData.append('amount', data.amount);
    formData.append('description', data.description);
    formData.append('budget', data.budget);
    formData.append('type', data.type);
    formData.append('account', data.account);
    const resp = await fetch(config.api_endpoint + 'transactions/add', {
        method: 'POST',
        headers: {
            'Authorization': authToken,
        },
        body: formData,
    });

    return await resp.json();
});

export const slice = createSlice({
    name: 'transaction',
    initialState: {
        all: []
    },
    extraReducers: {
        [fetchTransactions.fulfilled]: (state, action) => {
            state.all = action.payload;
        },
        [addTransaction.fulfilled]: (state, action) => {
            state.all.push(action.payload);
        }
    }
});

export default slice.reducer;