import {useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import { useDispatch } from 'react-redux';
import { signupUser } from '../redux/auth';
import { useState } from 'react';

import arrow1 from '../assets/images/arrow1.svg'
import Main from './Main';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import loading from '../assets/images/loading.svg'


export default function Signup() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [formError, setFormError] = useState(null);

    async function signup(values, {setSubmitting}) {
        //save details to remote server
        const response = await dispatch(signupUser(values.email, values.password));

        if (response.status === 201) {
            setFormError("There was an error submitting your form. Please try again later");

            //if successful, redirect to app
            history.push('/login');
            return;
        }

        setFormError(response.data.message);

    }

    const validate = values=>{
        const errors= {};

        // if (!values.firstName) {
        //     errors.firstName = 'Required'
        // } else if (values.firstName.length > 15) {
        //     errors.firstName = 'Must be 15 characters or less'
        // }

        // if (!values.lastName) {
        //     errors.lastName = 'Required'
        // } else if (values.lastName.length >20) {
        //     errors.lastName = 'Must be 20 characters or less'
        // }

        if (!values.email) {
            errors.email = 'Required'   
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }

          return errors
    }
    
    return (
        <div>
            <Main>
            <Formik
                initialValues={
                    {firstName: '', lastName:'', email: '', password: ''}
                }
                validate={validate}
                onSubmit={signup}
            >
                {
                    ({handleSubmit, values, errors, touched, handleChange, handleBlur, isSubmitting}) => (
                        <div className="bg-gray-800 min-h-screen pt-5">
                            <div className="justify-self-center mx-auto w-4/5 md:w-1/3">
                            
                            <form onSubmit={handleSubmit} className="">
                                <h2 className="text-white text-lg md:text-3xl mx-auto normal-case font-bold font-sans mb-5 md:mb-10 text-center">Create an account</h2>

                                {formError && (<div className="p-3 border-2 border-red-400 rounded-xl  text-gray-300 text-xs md:text-base">
                                            { formError }
                                            </div>)}
                                
                                {errors.firstName && touched.firstName ? <div className="mb-2">{errors.firstName}</div>: null} 
                                <input type="text" name="firstName" placeholder="First Name" className="bg-darker border-0 h-8 md:h-12 text-left pl-6 text-gray-100 mb-3 text-sm md:text-lg rounded-lg w-full"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName} />

                                {errors.lastName? <div className="text-red-400">{errors.lastName}</div>: null}<br/>
                                <input type="text" name="lastName" placeholder="Last Name" className="bg-darker border-0 h-8 md:h-12 text-left pl-6 text-gray-100 mb-3 text-sm md:text-lg rounded-lg w-full"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName} />
                                        
                                     {errors.email? <div className="text-red-400">{errors.email}</div>: null}
                                    <input type="email" name="email" placeholder="Email" className="bg-darker border-0 h-8 md:h-12 text-left pl-6 text-gray-100 mb-3 text-sm md:text-lg rounded-lg w-full"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email} /> <br/>
                                       

                                    <input type="password" name="password" placeholder="Password" className="bg-darker border-0 h-8 md:h-12 text-left pl-6 text-gray-100 mb-4 text-sm md:text-lg rounded-lg w-full"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}/> <br/>

                                    {!isSubmitting && <button type="submit" className="flex justify-between bg-red-400 border-0 h-8 md:h-12 p-2 text-gray-100 mb-10 rounded-lg w-full">
                                            <p className="font-semibold text-xs md:text-lg pl-4">Sign up</p>
                                            <img src={arrow1} alt="Sign up" className= "p-1 md:p-2 pr-2 md:pr-4 w-6 md:w-12" />

                                    </button>}

                                    {isSubmitting && <button type="submit" className="flex justify-between items-center bg-red-300 border-0 h-8 md:h-12 p-2 text-gray-100 mb-10 rounded-lg w-full">
                                        <p className="font-semibold text-xs md:text-lg pl-4">Loading</p>
                                        <img src={loading} alt="Login" className="p-1 md:p-2 pr-2 md:pr-4 w-6 md:w-12" />
                                    </button>}

                                    <div className="">
                                        <p className="text-sm md:text-base text-white">Already have an account? <Link to="/login" className="text-red-500 underline">Sign in</Link></p>
                                    </div>

                                </form>
                                </div>
                        </div>
                               
                    )
                }
            </Formik>
            </Main>
        </div>
    )
}