                   
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProfile } from '../redux/auth';
import ellipse from '../assets/images/ellipse.svg'
import Main from './Main';

export default function Homepage() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]);
    
    return (
            
            
            <div class="bg-gray-800 min-h-screen w-full">
                <Main>

              <div class="flex flex-col md:flex-row md:justify-between p-5 md:p-10">
                <div class="w-full md:w-1/2 mb-10 md:mb-4">
                    <h3 class="font-bold text-xl md:text-6xl text-white mb-5 md:mb-10 md:leading-tight">
                        Making your financial life easier.
                    </h3>
                    <p class="text-sm md:text-xl text-gray-300 mb-8 ">
                    Gain complete control of your financial life by practising mindful spending.
Budgetta takes care of your finances so you don’t have to.
                    </p>

                    {(user === null) ?

                    <Link to="signup" class="bg-green-400 inline text-white text-xs md:text-sm py-2 px-6 border-2 border-white hover:bg-green-200 rounded-full">Create an account
                    </Link>   
                    
                    : <Link to="/dashboard" class="inline bg-green-400 hover:bg-green-200 text-white text-sm py-2 md:py-3 px-6 border-2 border-white rounded-full">Go to Dashboard</Link>

                }
                </div>
                <div class="flex place-self-center mb-8 md:mb-16">
                    <img src={ellipse} alt="ellipse" class="block w-56 md:w-96"/>
                </div>
            </div>

                </Main>
                               
            </div>
    )
}