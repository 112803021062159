import { Field, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { addAccount } from '../redux/account.js';
import Authenticated from './Authenticated.js';
import Navbar from './navbar.js';

const EditAccount =()=> {    
    const history = useHistory()
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user)

    const formik = useFormik({
        initialValues: {
            name:'',
            balance: '',
            
        },
        onSubmit: values => {
            dispatch(addAccount({
                name:values.name,
                balance: values.balance,
            }))

            history.push('/dashboard')
        }
    });

    return (
        <Authenticated>
             <Navbar user={user} />
            <div className="justify-center w-80 mx-auto">
                <FormikProvider value={formik} >
                    <h1 className="text-xl font-bold color-black mb-6"> Add Account</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-4">
                            <Field className="bg-gray-200 w-80 h-10 rounded-xl px-4 py-2 mb-4 text-left text-lg" value={formik.values.name} placeholder="Name" name="name" />
                            <Field className="bg-gray-200 w-80 h-10 rounded-xl px-4 py-2 mb-4 text-left text-lg" value={formik.values.balance} placeholder="Account Balance" name="balance" />
                        </div>
                        <div>
                            <button type="submit" className="bg-pink-400 rounded-xl px-8 py-2 text-lg font-sans hover:bg-pink-500 text-white m-2"> Save </button>
                            <Link to="dashboard" className="bg-gray-200 rounded-xl px-8 py-2 text-lg font-sans hover:bg-gray-300 text-white"> Cancel </Link>
                        </div>
                    </form>
                </FormikProvider>
            </div>
            
        </Authenticated>
            
    )
}

export default EditAccount