import React, { useState } from "react";
import Authenticated from "./Authenticated";
import Navbar from "./navbar";
import { useSelector } from "react-redux";
import { Field, Formik } from "formik";
// import { CardActions } from '@material-ui/core';

const PayBills = () => {
  const user = useSelector((state) => state.auth.user);
  const [formSuccess, setFormSuccess] = useState(null);

  return (
    <Authenticated>
      <Navbar user={user} />
      <div className="flex mb-6 w:mb-8">
        <p className="border-b-4 border-blue-200 font-bold text-sm md:text-xl w-3/6 md:w-48 pb-2">
          Purchase Airtime
        </p>
        <div className="border-b-2 border-gray-200 w-full"></div>
      </div>

      <div className="flex justify-between flex-col md:flex-row md:gap-10">
        <div className="w-full md:w-1/2">
          {formSuccess && (
            <div className="text-xs md:text-base text-gray-500 rounded-xl px-4 py-2 bg-green-300 mb-3 md:mb-6">
              {formSuccess}
            </div>
          )}

          <Formik
            initialValues={{
              selectNetwork: "",
              amount: "",
              mobileNumber: "",
              cardNumber: "",
              pin: "",
            }}
            onSubmit={() => {
              window
                .open("https://www.quickteller.com/buy-airtime", "_blank")
                .focus();
              setFormSuccess(
                "You have been redirected to the quickteller page to complete your purchase."
              );
            }}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field
                    className="bg-gray-200 rounded-xl w-full md:w-3/4 p-2 h-10 text-left mb-2 md:mb-4 text-sm md:text-lg"
                    value={values.selectNetwork}
                    name="selectNetwork"
                    placeholder="Select a network"
                  />
                  <Field
                    className="bg-gray-200 rounded-xl w-full md:w-3/4 p-2 h-10 text-left mb-2 md:mb-4 text-sm md:text-lg"
                    value={values.amount}
                    name="amount"
                    placeholder="Amount"
                  />
                  <Field
                    className="bg-gray-200 rounded-xl w-full md:w-3/4 p-2 h-10 text-left mb-2 md:mb-4 text-sm md:text-lg"
                    value={values.mobileNumber}
                    name="mobileNumber"
                    placeholder="Mobile number"
                  />
                  <Field
                    className="bg-gray-200 rounded-xl w-full md:w-3/4 p-2 h-10 text-left mb-2 md:mb-4 text-sm md:text-lg"
                    value={values.cardNumber}
                    name="cardNumber"
                    placeholder="Card number"
                  />
                  <Field
                    className="bg-gray-200 rounded-xl w-full md:w-3/4 p-2 h-10 text-left mb-3 md:mb-4 text-sm md:text-lg"
                    value={values.pin}
                    name="pin"
                    placeholder="Pin"
                  />
                </div>

                <button
                  type="submit"
                  className="bg-pink-400 rounded-xl px-6 py-1 text-lg font-sans hover:bg-pink-500 text-white"
                >
                  {" "}
                  Buy{" "}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Authenticated>
  );
};

export default PayBills;
